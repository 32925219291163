import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import heroImage from './Assets/hero.svg'; // Replace with your image path
import logo from './Assets/logo.png'; // Replace with your logo path
import COLORS from '../constants/Colors'; // Replace with your colors if needed

function ComingSoonHero() {
  const theme = useTheme();

  const calculateTimeLeft = () => {
    const launchDate = new Date('2025-01-01T00:00:00'); // Replace with your launch date
    const now = new Date();
    const difference = launchDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        position: 'relative',
        overflow: 'hidden',
        padding: {
          xs: '20px 10px',
          xl: '100px 20px',
        },
        display: 'flex',
        alignItems: 'center',
        minHeight: '75vh',
      }}
    >
      {/* Background Shapes */}
      {[
        { bottom: '-120px', right: '-180px', size: 300, color: 'rgba(255, 0, 0, 0.3)', blur: '100px', duration: '6s' },
        { top: '30%', left: '40%', size: 200, color: 'rgba(0, 255, 0, 0.3)', blur: '100px', duration: '10s' },
        { bottom: '20%', right: '20%', size: 250, color: 'rgba(255, 255, 0, 0.3)', blur: '100px', duration: '9s' },
        { top: '10%', right: '30%', size: 220, color: 'rgba(0, 255, 255, 0.3)', blur: '110px', duration: '7s' },
      ].map((shape, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            width: `${shape.size}px`,
            height: `${shape.size}px`,
            background: shape.color,
            borderRadius: '50%',
            filter: `blur(${shape.blur})`,
            animation: `move${index} ${shape.duration} ease-in-out infinite alternate`,
            ...('top' in shape ? { top: shape.top } : { bottom: shape.bottom }),
            ...('left' in shape ? { left: shape.left } : { right: shape.right }),
            zIndex: -1,
          }}
        />
      ))}

      {/* Keyframe Animations */}
      <style>
        {`
          @keyframes move1 { 0% { transform: translateY(0); } 100% { transform: translateY(-40px); } }
          @keyframes move2 { 0% { transform: translateY(0); } 100% { transform: translateY(50px); } }
          @keyframes move3 { 0% { transform: translateY(0); } 100% { transform: translateY(-30px); } }
          @keyframes move4 { 0% { transform: translateY(0); } 100% { transform: translateY(40px); } }
        `}
      </style>

      <Grid container spacing={2} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
        {/* Left Column */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: '150px',
              height: 'auto',
              marginBottom: 4,
            }}
          />
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 600,
              fontSize: { xs: '2.5rem', md: '3rem' },
              color: COLORS.primary,
              marginBottom: 2,
            }}
          >
            Coming Soon!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              color: COLORS.text,
              fontSize: { xs: '0.9rem', md: '1rem' },
              marginBottom: 4,
            }}
          >
            Our new and improved website is under construction. Stay tuned for
            updates as we work hard to bring you an enhanced experience. Thank
            you for your patience and support!
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                color: COLORS.text,
                marginBottom: 2,
                fontSize: '14px',
              }}
            >
              Launch In:
            </Typography>
            <Grid container spacing={2}>
              {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
                <Grid item xs={3} key={unit}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: COLORS.primary,
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px',
                      minWidth: '60px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: { xs: '1rem', md: '1.2rem' },
                      }}
                    >
                      {timeLeft[unit] || 0}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: { xs: '0.75rem', md: '0.85rem' },
                      }}
                    >
                      {unit}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box
              component="img"
              src={heroImage}
              alt="Hero"
              sx={{
                width: { xs: '90%', md: '70%' },
                height: 'auto',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ComingSoonHero;
