import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ComingSoonPage from './Components/ComingSoonPage';

const theme = createTheme({
  palette: {
    mode: 'dark', // Set the mode to dark
    primary: {
      main: '#ff6f61',
    },
    secondary: {
      main: '#ffbb33',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ComingSoonPage />
    </ThemeProvider>
  );
}

export default App;
